@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.cdnfonts.com/css/kingvoon");
@import url("https://fonts.cdnfonts.com/css/brin-athyn");
@import url("https://fonts.cdnfonts.com/css/erin-go-bragh");
@import url("https://fonts.cdnfonts.com/css/montserrat");

body {
  background-color: #e2dbc8;
}

.king {
  font-family: "Kingvoon", sans-serif;
}

.brin {
  font-family: "Brin Athyn", sans-serif;
}

.erin {
  font-family: "Erin Go Bragh", sans-serif;
}

.welcome {
  background-image: url(./assets/welcomebg.png);
  background-size: cover;
}

.cards {
  background-image: url(./assets/noise.png);
  background-size: cover;
}

.monts {
  font-family: "Montserrat", sans-serif;
}

.red {
  color: #680000;
}

.Hero {
  background-image: url(./assets/trumpvid.MP4);
  background-size: cover;
}
