input {
  display: none;
}

img {
  width: 100%;
  height: 100%;
}

.book {
  display: flex;
}

.flip-book {
  perspective: 1500px;
}

.flip {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform-style: preserve-3d;
  transform-origin: left;
  transform: rotateY(0deg);
  transition: 0.5s;
}

p {
  font-size: 14px;
  line-height: 24px;
}

.front {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #e6b170;
  padding: 0px 13px;
  box-shadow: inset 20px 0 50px rgba(0, 0, 0, 0.5) 0 2px 5px rgba(0, 0, 0, 0.5);
}

.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  background-color: #e6b170;
}

.next-btn {
  position: absolute;
  bottom: 13px;
  right: 13px;
  cursor: pointer;
  color: #680000;
}

.back-btn {
  position: absolute;
  bottom: 13px;
  left: 13px;
  cursor: pointer;
  color: #680000;
}

#p1 {
  z-index: 3;
}

#p2 {
  z-index: 2;
}

#p3 {
  z-index: 1;
}

#c1:checked ~ .flip-book #p1 {
  transform: rotateY(-180deg);
  z-index: 1;
}

#c2:checked ~ .flip-book #p2 {
  transform: rotateY(-180deg);
  z-index: 2;
}

#c3:checked ~ .flip-book #p3 {
  transform: rotateY(-180deg);
  z-index: 3;
}

/* Mobile styles */
@media (max-width: 640px) {
  .flip-book {
    width: 170px; /* Adjust this width for mobile */
    height: 380px; /* Adjust this height for mobile */
  }

  .front,
  .back {
    padding: 0 10px; /* Reduce padding for mobile */
  }

  .front {
    width: 200px;
  }

  .back {
    width: 200px;
  }

  .text-4xl {
    font-size: 1.5rem; /* Adjust font size for mobile */
  }

  .text-xl {
    font-size: 1rem; /* Adjust font size for mobile */
  }

  .next-btn,
  .back-btn {
    font-size: 1.5rem; /* Adjust button size for mobile */
  }
}
